<script setup lang="ts">
import Button from "~/components/inputs/Button.vue";
import type { IRate } from "~/interfaces";
import DropdownArrow from "~/components/icons/dropdown-arrow/DropdownArrow.vue";

const props = defineProps({
  model: { type: Object as () => IRate, required: true },
  active: { type: Boolean, required: false, default: true },
  type: { type: String, required: false, default: "limited" },
  text: { type: String, required: false, default: "" },
  textAlt: { type: String, required: false, default: "" },
});

const emit = defineEmits(["selected", "submit"]);

formatPriceValues(props.model);
</script>

<template>
  <div v-if="active" class="proposal open border-yellow" :class="`proposal-${type} primary`">
    <div class="finance-container bg-yellow">
      <span v-if="type === 'limited'" class="whitespace-no-wrap ml-2 lg:mr-0 lg:hidden"
        >Importo di</span
      >
      <span
        class="font-semibold ml-2 mr-auto lg:mr-0 lg:ml-0 p-2 whitespace-no-wrap"
        >{{ model.imp_finanz_text }}</span
      >
      <a class="sm:text-base underline mr-4" target="_blank" :href="model.secci_url"
        >SECCI</a
      >
    </div>
    <div class="text-container">
      <p v-if="!text">
        <span class="font-bold">{{ model.imp_rata }}</span> € per
        <span class="font-bold">{{ model.rate }}</span> mesi TAEG
        <span class="font-bold">{{ model.taeg }}</span> %
      </p>
      <p v-else v-html="text"></p>
    </div>
    <div class="button-container">
      <Button :uppercase="true" :rounded="true" data-testid="rataActiveSubmit" @click="emit('submit', model)"
        >CONTINUA</Button
      >
    </div>
  </div>
  <div
    v-else
    class="proposal close justify-between"
    :class="[`proposal-${type} primary`]"
    @click="emit('selected', model)"
  >
    <div class="finance-container import">
      <strong class="xl:ml-10 0 p-2 whitespace-no-wrap">{{
        model.imp_finanz_text
      }}</strong>
    </div>
    <div class="finance-container finance">
      <a class="sm:text-base underline mr-4" target="_blank" :href="model.secci_url"
        >SECCI</a
      >
    </div>
    <div class="button-container">
      <Button :uppercase="true" :rounded="true" data-testid="rataSubmit" @click="emit('submit', model)">
        CONTINUA</Button
      >
    </div>
    <div class="text-container">
      <p v-if="!textAlt">
        <strong class="ml-2 lg:ml-10 lg:hidden">{{
          model.imp_finanz_text
        }}</strong>
        <span class="lg:hidden ml-1 sm:ml-4 mr-1">rata</span>
        <span
          ><strong>{{ model.imp_rata }}</strong> € per
          <strong>{{ model.rate }}</strong> mesi TAEG
          <strong>{{ model.taeg }}</strong
          >%</span
        >
      </p>
      <p v-else v-html="textAlt"></p>
    </div>
  </div>
</template>

<style scoped></style>
